<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-4>
          <v-layout row justify-space-between align-center >
            <v-flex xs4>
              <span class="heading">Rescuer List</span>
            </v-flex>
            <v-flex xs12 sm6 md6 lg6 pt-2 align-center>
                 
              <v-select
                outlined
                hide-details
                dense clearable
                :items="districts"
                v-model="district"
                placeholder="Filter By District"
                style="font-family: sofiaProRegular;"
              >
              </v-select>
            </v-flex>
            <v-flex xs2 align-center mt-2 class="text-right">
              <v-btn
                color="#427D2D"
                class="downlink"
                target="_blank"
                @click="getExcel()"
              >
                <span
                  style="
                    font-size: 12px;
                    font-family: poppinssemibold;
                    color: white;
                  "
                >
                  Excel
                  <v-icon color="white">mdi-microsoft-excel</v-icon>
                </span>
              </v-btn>
            </v-flex>
          </v-layout>
          
          <!-- <v-flex xs12>
            <span class="heading">Rescuer List</span>
          </v-flex>
          <v-flex xs12>
            <v-btn
              color="#427D2D"
              class="downlink"
              target="_blank"
              @click="getExcel()"
            >
              <span
                style="
                  font-size: 12px;
                  font-family: poppinssemibold;
                  color: black;
                "
              >
                Excel<v-icon color="white">mdi-microsoft-excel</v-icon></span
              >
            </v-btn>
          </v-flex> -->
          <v-flex xs12 py-4 v-if="userlist && userlist.length > 0">
            <v-layout wrap justify-start pt-2>
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto; overflow-x: auto;">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablehead">Rescuer Code</th>
                            <th class="text-left tablehead">Name</th>
                            <th class="text-left tablehead">Phone</th>
                            <th class="text-left tablehead">Email</th>
                            <th class="text-left tablehead">District</th>
                            <th class="text-left tablehead">Range </th>
                            <th class="text-left tablehead">Division</th>
                            <!-- <th class="text-left tablehead">Division</th> -->
                          
                            <th class="text-left tablehead">Action</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in userlist" :key="index">
                            <td style="white-space: nowrap;" class="tableitems">{{ item.rescuerCode }}</td>
                            <td style="white-space: nowrap;" class="tableitems">{{ item.user.name }}</td>
                            <td style="white-space: nowrap;" class="tableitems">{{ item.user.phone }}</td>
                            <td style="white-space: nowrap;" class="tableitems">{{ item.user.email }}</td>
                            <td style="white-space: nowrap;" class="tableitems">{{ item.district }}</td>
                            <td style="white-space: nowrap;" class="tableitems"><span  v-if="item && item.user && item.user.range && item.user.range[0].rangeName">{{ item.user.range[0].rangeName }}</span></td>
                        <td style="white-space: nowrap;" class="tableitems"><span  v-if="item && item.user && item.user.division && item.user.division[0].divisionName">{{ item.user.division[0].divisionName }}</span></td>

                            <td style="white-space: nowrap;">

                              <v-icon
                              small
                              color="success"
                              class="ml-1"
                              @click.stop="openviewdetails(item)"
                            >
                              mdi-eye
                            </v-icon>
                              <!-- <v-icon
                              small
                              color="primary"
                              class="ml-2"
                              @click.stop="openeditdetails(item)"
                            >
                              mdi-pencil
                            </v-icon>

                            <v-icon
                            small
                            color="error"
                            class="ml-2"
                            @click.stop="rejectDialog(item.user._id)"
                          >
                            mdi-delete
                          </v-icon> -->

                              <!-- <v-btn
                                color="primary"
                                depressed
                                small
                                @click="openeditdetails(item)"
                              >
                                <span>Edit</span>
                              </v-btn>

                              <v-btn
                                color="success"
                                depressed
                                small
                                @click="openviewdetails(item)"
                              >
                                <span>View Details</span>
                              </v-btn>

                              <v-btn
                              color="error"
                              depressed
                              small
                              @click="rejectDialog(item.user._id)"
                            >
                              <span>Delete</span>
                            </v-btn> -->


                            </td>
                          
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    </div>
                 
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-4 v-if="userlist && userlist.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              circle
              color="#427D2D"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout
          wrap
          justify-center
          align-center
         
        >
          <v-flex xs12 class="text-center">
            <span class="nodata">No Data Found !</span>
          </v-flex>
        </v-layout>
      </v-flex>
      
    </v-layout>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Delete Rescuer</v-card-title>
        <v-card-text>
          Are you sure you want to delete this rescuer?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteUser()"
            class="itemValue"
          >
            <span style="color: #fff">Confirm</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
      <script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      district:"",
      districts: [
        "All",
        "Angul",
        "Boudh",
        "Balangir",
        "Bargarh",
        "Balasore",
        "Bhadrak",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghapur",
        "Khordha",
        "Keonjhar",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundargarh",
      ],
      deleteDialog: false,
    };
  },
  beforeMount() {
    this.getData();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage=1
    },
    district() {
      this.getData();
    },
  },
  methods: {
    openviewdetails(item) {
      this.$router.push({
        path: "/officers/rescuerslistview",
        query: {
          id: item._id,
        },
      });
    },
    getExcel() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/getlist/download/excel",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        responseType: "blob",
        params: {
          //keyword: this.keyword,
          keyword:this.district
        },
      })
        .then((response) => {
          this.appLoading = false;
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rejectDialog(item) {
      this.deleteDialog = true;
      this.deleteid = item;
    },
    openeditdetails(item) {
      this.$router.push({
        path: "/newrescuerslistedit",
        query: {
          id: item.user._id,
        },
      });
    },
    deleteUser() {
      this.appLoading = true;
      axios({
        method: "DELETE",
        url: "/user/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
              this.getData();
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/rescuer/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.keyword,
          district: this.district,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.userlist = response.data.data;
              this.Pagelength = response.data.pages;
              this.msg = response.data.msg;
              this.showSnackBar = false;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    handlesubmit() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/snake/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          name: this.commonname,
          scientificName: this.scientificname,
          states: this.state,
          rname: this.reginonalname,
          distribution: this.distribution,
          characteristics: this.scalation,
          description: this.description,
          venomType: this.venomtype,
          family: this.family,
          occurence: this.occurence,
          wlpa: this.wlpa,
          iucn: this.iucn,
          shortdes: this.shortdescription,
          othernames: this.othername,
          genus: this.genus,
          experts: this.experts,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
              this.commonname = "";
              this.scientificname = "";
              this.state = "";
              this.reginonalname = "";
              this.distribution = "";
              this.scalation = "";
              this.description = "";
              this.venomtype = "";
              this.family = "";
              this.occurence = "";
              this.wlpa = "";
              this.iucn = "";
              this.shortdescription = "";
              this.othername = "";
              this.genus = "";
              this.experts = "";
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
  },
};
</script>
      <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
</style>